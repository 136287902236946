import queryString from 'querystring';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useHistory, useLocation } from 'react-router-dom';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
// @ts-ignore
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';

import { Category } from '@ttstr/api';
import { Navigation, useAuth, useProductDetails } from '@ttstr/components';
import MenuPortal from '@ttstr/components/Navigation/MenuPortal';
import UserLoginModal from '@ttstr/components/Navigation/UserLoginModal';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { categories, categoriesReceived } = useProductDetails();
  const { loggedIn, showLogin, setShowLogin, redirectAfterLogin } = useAuth();

  const qs = React.useMemo(() => {
    return queryString.parse(location.search.substring(1));
  }, [location.search]);

  const [mainMenuRef, setMainMenuRef] = React.useState<HTMLDivElement>(); // Could be put into Context
  const [customerMenuRef, setCustomerMenuRef] = React.useState<HTMLLIElement>(); // Could be put into Context
  const [navbarRef, setNavbarRef] = React.useState<HTMLUListElement>(); // Could be put into Context

  const toggleUserLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const isRoot = React.useMemo(() => {
    const matches = matchPath(location.pathname, { path: '/', exact: true });
    return matches && matches.isExact;
  }, [location.pathname]);

  const selectedCategoryId = qs.categoryId;

  return (
    <header id="header" className="d-print-none">
      <Navigation
        logo={require('./assets/images/logo.png')}
        light
        // color="white"
        cartIcon={<i className="fal fa-shopping-bag" />}
        mainMenuRef={setMainMenuRef}
        customerMenuRef={setCustomerMenuRef}
        navbarRef={setNavbarRef}
        alwaysCloseMainMenu
        showRegister={false}
        showLanguageSwitcher={false}
      />

      {/* <MenuPortal target={navbarRef}>
      </MenuPortal> */}

      <MenuPortal target={mainMenuRef}>
        <Nav navbar>
          <NavItem>
            <NavLink to="/" exact replace={isRoot} className="nav-link">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/products" exact replace={isRoot} className="nav-link">
              Alle Produkte {/* {t(`NAVIGATION.ALL`)} */}
            </NavLink>
          </NavItem>
          <div className="my-3">
            {categoriesReceived &&
              Object.values(categories)
                .sort((a: Category, b: Category) => a.position - b.position)
                .map((c: Category) => (
                  <NavItem key={c.id}>
                    <NavLink
                      to={`/products?categoryId=${c.id}#product-listing`}
                      replace={isRoot}
                      isActive={() => selectedCategoryId === String(c.id)}
                      className="nav-link nav-link-cat"
                    >
                      {c.title}
                    </NavLink>
                  </NavItem>
                ))}
          </div>
        </Nav>
      </MenuPortal>

      <MenuPortal target={customerMenuRef}>
        {loggedIn ? (
          <UncontrolledDropdown>
            <DropdownToggle nav caret title={t(`NAVIGATION.ACCOUNT`)} className="text-info">
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={NavLink} to="/customer/account" exact>
                {t(`CUSTOMER.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/orders" exact>
                {t(`ORDERS.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/landing-page" exact>
                {t(`CUSTOMER.LANDING_PAGE.TITLE`)}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={NavLink} to="/customer/logout">
                {t('CUSTOMER.LOGOUT')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavItem tag="div">
            <NavLink
              to="#"
              isActive={() => false}
              onClick={(e) => {
                e.preventDefault();
                toggleUserLoginModal();
              }}
              className="nav-link"
              title={t(`NAVIGATION.ACCOUNT`)}
            >
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </NavLink>
          </NavItem>
        )}
        <UserLoginModal
          show={showLogin}
          toggle={toggleUserLoginModal}
          onLoginSuccess={() =>
            redirectAfterLogin && history.push(typeof qs.success === 'string' ? qs.success : '/customer/landing-page')
          }
        >
          <div className="alert alert-default p-0">{t(`LOGIN.INTRO`)}</div>
        </UserLoginModal>
      </MenuPortal>
    </header>
  );
};

export default React.memo(Header);
